import {
  Circle,
  type StackProps,
  type TextProps,
  type ThemeName,
  XStack,
} from "tamagui";

import { Paragraph } from "./Typography";

export type IssueStatus = "CLOSED" | "NEW_ACTIVITY" | "PROCESSING";

// TODO: Switch to theme colors
export const getChatPillStyle = (status: IssueStatus): PillProps => {
  switch (status) {
    case "CLOSED":
      return { text: "Resolved", notificationDot: false, theme: "gray" };
    case "NEW_ACTIVITY":
      return {
        text: "New",
        notificationDot: true,
        theme: "green",
      };
    default:
      return {
        text: "Processing",
        notificationDot: false,
        theme: "lightBlue",
      };
  }
};

export type PillProps = {
  text: string;
  notificationDot?: boolean;
  theme?: ThemeName;
  wrapperProps?: StackProps;
  textProps?: TextProps;
};

export const Pill = ({
  text,
  notificationDot,
  theme = "alt1",
  wrapperProps,
  textProps,
}: PillProps) => {
  return (
    <XStack
      theme={theme}
      ai="center"
      jc="space-evenly"
      br="$3"
      // No matching tokens
      px={6}
      py={4}
      bg="$color3"
      gap={6}
      borderWidth={0.5}
      borderColor="$color4"
      {...wrapperProps}
    >
      {notificationDot ? (
        <Circle height={5} width={5} zi={2} backgroundColor="$color13" />
      ) : null}
      <Paragraph size="$2" color="$color13" zi={2} {...textProps}>
        {text}
      </Paragraph>
    </XStack>
  );
};
