import React, { useCallback, useEffect, useState } from "react";
import {
  type LayoutChangeEvent,
  type NativeSyntheticEvent,
  type TextInputKeyPressEventData,
} from "react-native";

import { Plus as PlusIcon, Send as SendIcon } from "@tamagui/lucide-icons";

import {
  Circle,
  Spinner,
  TextArea,
  XStack,
  YStack,
  getTokenValue,
  isWeb,
  styled,
} from "@medbillai/ui";

import { chatControlsXPadding } from "../utils/constants";

const InputWrapper = styled(YStack, {
  variants: {
    homePage: {
      true: {
        position: "absolute",
        width: "100%",
        bottom: "$3",
        zIndex: 10,
      },
    },
  } as const,
});

export type CaseInputProps = {
  handleSendMessage: (message: string) => void;
  openUploadSheet: () => void;
  sendMessageLoading: boolean;
  uploadDocumentLoading: boolean;
  suggestedMessage: string | undefined;
  hidePrompt?: (hidden: boolean) => void;
  homePage?: boolean;
};

const baseTextInputHeight = getTokenValue("$3.5", "size") as number;
const inputFontSize = getTokenValue("$3", "fontSize") as number;
const sendButtonSize = getTokenValue("$2.5", "size") as number;
const borderSize = 0.5;
const sendButtonYOffset =
  ((baseTextInputHeight - sendButtonSize) / 2) * borderSize + 1.5;

function CaseInput({
  handleSendMessage,
  sendMessageLoading,
  uploadDocumentLoading,
  openUploadSheet,
  suggestedMessage,
  hidePrompt,
  homePage,
}: CaseInputProps) {
  const [message, setMessage] = useState<string>("");
  const [isPromptHidden, setIsPromptHidden] = useState(false);

  const handleMessageKeyDown = (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>,
  ) => {
    if (isWeb && event.nativeEvent.key === "Enter") {
      event.preventDefault();
      handleSubmitMessage();
    }
  };

  const handleChangeMessage = useCallback((text: string) => {
    setMessage(text);
  }, []);

  // Handle layout change and check the width
  const handleLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const { height } = event.nativeEvent.layout;
      // Hide or show the prompt based on the input width
      if (height > 60 && !isPromptHidden && hidePrompt) {
        hidePrompt(true);
        setIsPromptHidden(true);
      } else if (height <= 65 && isPromptHidden && hidePrompt) {
        hidePrompt(false);
        setIsPromptHidden(false);
      }
    },
    [isPromptHidden],
  );

  const handleSubmitMessage = () => {
    if (sendMessageLoading || uploadDocumentLoading) {
      return;
    }
    handleSendMessage(message);
    setMessage("");
  };

  const handleUploadPress = () => {
    if (sendMessageLoading || uploadDocumentLoading) {
      return;
    }
    openUploadSheet();
  };

  useEffect(() => {
    if (suggestedMessage && suggestedMessage !== message) {
      setMessage(suggestedMessage);
    }
  }, [suggestedMessage]);

  return (
    <InputWrapper homePage={homePage}>
      <XStack
        gap="$2"
        ai="flex-end"
        px={chatControlsXPadding}
        pb="$2"
        pt="$1.5"
      >
        <XStack
          ai="flex-end"
          bw={borderSize}
          br={(baseTextInputHeight + 2 * borderSize) / 2}
          borderColor="$borderColor"
          pl={homePage ? "$3" : "$0"}
          mih={baseTextInputHeight}
          f={1}
        >
          {!homePage && (
            <Circle
              backgroundColor="transparent"
              w={sendButtonSize}
              mr={sendButtonYOffset}
              ml="$2"
              mb={sendButtonYOffset}
              animation="200ms"
              pressStyle={{
                rotate: "110deg",
              }}
              disabled={sendMessageLoading || uploadDocumentLoading}
              h={sendButtonSize}
              onPress={handleUploadPress}
            >
              {uploadDocumentLoading ? (
                <Spinner size="small" />
              ) : (
                <PlusIcon
                  size={baseTextInputHeight * 0.5}
                  strokeWidth={2}
                  color="$textSecondary"
                />
              )}
            </Circle>
          )}
          <TextArea
            unstyled
            f={1}
            rows={1}
            placeholder="Message our care team"
            placeholderTextColor="$textSecondary"
            onChangeText={handleChangeMessage}
            value={message}
            onLayout={handleLayout}
            onSubmitEditing={handleSubmitMessage}
            onKeyPress={handleMessageKeyDown}
            outlineStyle="none"
            fontSize={inputFontSize}
            fontFamily="$body"
            color="$color"
            style={{
              paddingBottom: inputFontSize * 0.75,
              paddingTop: inputFontSize * 0.75,
            }}
            {...(!isWeb && { accessibilityHint: "Other actions" })}
          />
          <Circle
            bc="$color10"
            w={sendButtonSize}
            mr={sendButtonYOffset}
            ml="$2.5"
            mb={sendButtonYOffset}
            disabled={!(message || sendMessageLoading || uploadDocumentLoading)}
            animation="bouncyTight"
            o={message || sendMessageLoading ? 1 : 0}
            h={sendButtonSize}
            onPress={handleSubmitMessage}
            {...(!isWeb && { accessibilityHint: "Send message" })}
          >
            {sendMessageLoading || uploadDocumentLoading ? (
              <Spinner size="small" color="$background" />
            ) : (
              <SendIcon
                color="$background"
                size={baseTextInputHeight * 0.4}
                strokeWidth={2}
              />
            )}
          </Circle>
        </XStack>
      </XStack>
    </InputWrapper>
  );
}

export default React.memo(CaseInput);
