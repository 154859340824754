import { ScrollView } from "react-native";

import { XStack, YStack } from "@medbillai/ui";

import SelectableChatSuggestion from "./SelectableChatSuggestion";
import {
  messageSuggestionsRow1,
  messageSuggestionsRow2,
} from "./utils/constants";

export const HomePageIssueOverlay = ({
  selectMessageSuggestion,
  hidePrompt,
}: {
  selectMessageSuggestion: (message: string) => void;
  hidePrompt: boolean;
}) => {
  const selectRowOne = (index: number) => {
    if (messageSuggestionsRow1[index]) {
      const message = messageSuggestionsRow1[index].message;
      selectMessageSuggestion(message);
    }
  };
  const selectRowTwo = (index: number) => {
    if (messageSuggestionsRow2[index]) {
      const message = messageSuggestionsRow2[index].message;
      selectMessageSuggestion(message);
    }
  };

  return (
    <YStack
      width="100%"
      zIndex={3}
      animation="200ms"
      opacity={hidePrompt ? 0 : 1}
      transform={[{ translateY: hidePrompt ? -10 : 0 }]}
    >
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        scrollEnabled={true}
        decelerationRate="fast"
        removeClippedSubviews={true}
        style={{ width: "100%" }}
        contentOffset={{ x: 750, y: 0 }}
      >
        <YStack gap="$2" width="100%" paddingHorizontal="$2">
          <XStack key="view_key1" height="$4">
            {messageSuggestionsRow1.map((option, index) => (
              <SelectableChatSuggestion
                key={`view_key1${option.message}`}
                index={index}
                title={option.title}
                onPress={selectRowOne}
              />
            ))}
          </XStack>
          <XStack key="view_key2" height="$4" pl="$6">
            {messageSuggestionsRow2.map((option, index) => (
              <SelectableChatSuggestion
                key={`view_key2${option.message}`}
                index={index}
                title={option.title}
                onPress={selectRowTwo}
              />
            ))}
          </XStack>
        </YStack>
      </ScrollView>
    </YStack>
  );
};
