import {
  ChevronRight as ChevronRightIcon,
  Plus as PlusIcon,
} from "@tamagui/lucide-icons";

import { Button, Paragraph2, Subheadline5, XStack } from "@medbillai/ui";

export const WidgetHeader = ({
  label,
  onPress,
}: {
  label: string;
  onPress?: () => void;
}) => {
  return (
    <XStack
      minHeight="$2"
      paddingHorizontal="$4"
      jc="space-between"
      width="100%"
      pb="$2"
      alignItems="center"
    >
      <Subheadline5>{label}</Subheadline5>
      {onPress ? (
        <Button
          key="newIssueButton"
          circular
          icon={PlusIcon}
          size="$2"
          scaleIcon={1}
          onPress={onPress}
          animation="bouncyTight"
          justifyContent="center"
          alignItems="center"
          scale={1}
          pressStyle={{
            transform: [{ scale: 0.9 }],
          }}
        />
      ) : null}
    </XStack>
  );
};

export const NavButton = ({
  label,
  onPress,
}: {
  label: string;
  onPress: () => void;
}) => {
  return (
    <XStack
      alignItems="center"
      backgroundColor="$lightBlue2"
      paddingLeft="$4"
      paddingRight="$2"
      paddingVertical="$2"
      justifyContent="flex-end"
      br="$10"
      animation="bouncyTight"
      scale={1}
      pressStyle={{
        transform: [{ scale: 0.9 }],
      }}
      onPress={onPress}
    >
      <Paragraph2 key={label} color="$color8">
        {label}
      </Paragraph2>
      <ChevronRightIcon
        padding="$0"
        strokeWidth={1.5}
        color="$color8"
      ></ChevronRightIcon>
    </XStack>
  );
};
