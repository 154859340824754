// src/components/SelectableChatOption.tsx
import { memo } from "react";

import { Paragraph2, XStack } from "@medbillai/ui";

interface SelectableChatOptionProps {
  title: string;
  index: number;
  onPress: (index: number) => void;
}

const SelectableChatOption = ({
  title,
  index,
  onPress,
}: SelectableChatOptionProps) => {
  return (
    <XStack
      borderRadius={30}
      borderColor="$strokeSecondary"
      borderWidth={0.5}
      px="$3.5"
      py="$2.5"
      marginRight={10} // Adding margin for spacing
      alignItems="center"
      justifyContent="center"
      onPress={() => onPress(index)}
      animation="bouncyTight"
      pressStyle={{ scale: 0.95 }}
      scale={1}
    >
      <Paragraph2 theme="alt1">{title}</Paragraph2>
    </XStack>
  );
};

SelectableChatOption.displayName = "SelectableChatOption";
export default memo(SelectableChatOption);
