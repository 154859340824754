import { type ApolloCache } from "@apollo/client";

import type { MarkIssueAsSeenMutation } from "@medbillai/graphql-types";

import { type IssueStatus, type SelectBarTabs } from "@medbillai/ui";

import type { CaseScreenIssue } from "../../case/utils/types";
import { getIssuesQueryV2 } from "./queries";
import type { Issue, IssueV2, QueryIssuesV2 } from "./types";

export const pageSize = 15;

export const openFilter = {
  open: true,
  closed: false,
  unread: false,
  first: pageSize,
  after: 0,
};

export const unreadFilter = {
  closed: false,
  unread: true,
  open: false,
  first: pageSize,
  after: 0,
};

export const closedFilter = {
  unread: false,
  closed: true,
  open: false,
  first: pageSize,
  after: 0,
};

export const filterOptionIds = {
  open: "open",
  unread: "unread",
  closed: "closed",
};

export const filterOptions: SelectBarTabs = [
  { id: filterOptionIds.open, name: "Open" },
  { id: filterOptionIds.unread, name: "Unread" },
  { id: filterOptionIds.closed, name: "Closed" },
];

export const filterMap = {
  [filterOptionIds.open]: openFilter,
  [filterOptionIds.unread]: unreadFilter,
  [filterOptionIds.closed]: closedFilter,
};

export const formatEvent = (event: Issue["lastEvent"]) => {
  switch (event?.__typename) {
    case "issue__ChatEvent_Type":
      return event.chatBody;
    case "issue__ClosedEvent_Type":
      return "Issue closed";
    case "issue__DocumentUploadedEvent_Type":
      return "Document uploaded";
    case "issue__ReopenedEvent_Type":
      return "Issue reopened";
    case "issue__HipaaReleaseRequestedEvent_Type":
      return "HIPAA release requested";
    case "issue__TemplateReleaseRequestedEvent_Type":
      return `${event.template.description} requested`;
    case "issue__BillDisputeEvent_Type":
      return "Bill disputed";
    default:
      return null;
  }
};

export const getTextColor = (status: IssueStatus) => {
  return status === "NEW_ACTIVITY" ? "$color" : "$textSecondary";
};

export const getTimeColor = (status: IssueStatus) => {
  return status === "NEW_ACTIVITY" ? "$color" : "$textSecondary";
};

export const cleanMarkdownText = (text: string | null) => {
  if (!text) {
    return "";
  }
  return text.replace(/[\\^$*+?.()|[\]{}#]/g, "");
};

/**
 * Function to convert `openNewIssue` to `issue__Issue, Technically this could
 * also be a full getIssueQueryEvent but it will quickly get over written so for
 * type saftery we will convert it.
 */
export function convertToIssue(openNewIssue: CaseScreenIssue): IssueV2 {
  return {
    __typename: "issue__Issue_Type",
    id: openNewIssue.id,
    title: openNewIssue.title,
    openedAt: openNewIssue.openedAt,
    closedAt: openNewIssue.closedAt,
    lastEvent: openNewIssue.lastEvent,
    firstUnseenEvent: openNewIssue.firstUnseenEvent,
  };
}

/**
 * Optimistically updates the cache so that the issues list are synced
 */
export function updateIssuesQuery(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache: ApolloCache<any>,
  openNewIssue: CaseScreenIssue,
) {
  cache.updateQuery(
    {
      query: getIssuesQueryV2,
    },
    queryData => {
      if (!queryData?.queryIssuesV2) {
        return null;
      }

      const newIssue = convertToIssue(openNewIssue);

      const updatedEvents: QueryIssuesV2 = [
        newIssue,
        ...queryData.queryIssuesV2,
      ];

      return {
        ...queryData,
        queryIssuesV2: updatedEvents,
      };
    },
  );
}

/**
 * Optimistically updates the cache so that the notification statuses are synced.
 */
export function updateIssueAsSeenInCache(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache: ApolloCache<any>,
  issueId: string,
) {
  const cachedData = cache.readQuery({
    query: getIssuesQueryV2,
  });

  if (!cachedData?.queryIssuesV2) {
    return;
  }

  const updatedIssues = cachedData.queryIssuesV2.map(issue => {
    if (issue.id === issueId) {
      return {
        ...issue,
        firstUnseenEvent: null,
      };
    }
    return issue;
  });

  cache.writeQuery({
    query: getIssuesQueryV2,
    data: {
      ...cachedData,
      queryIssuesV2: updatedIssues,
    },
  });
}

export function handleUpdateForSeenIssue(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache: ApolloCache<any>,
  data: MarkIssueAsSeenMutation | null | undefined,
) {
  if (!data) {
    return;
  }
  const issueId = data.updateIssue?.id;
  if (issueId) {
    updateIssueAsSeenInCache(cache, issueId);
  }
}
