import { useQuery } from "@apollo/client";
import { memo } from "react";

import { ScreenView, type ScreenViewProps } from "../../components/ScreenView";
import { errorHandling } from "../../lib/apollo/utils";
import { useNormalizedEobs } from "../bill/utils/useNormalizedEobs";
import { allFilter } from "../bill/utils/utils";
import { getIssuesQueryV2 } from "../cases/utils/queries";
import { filterMap, filterOptionIds } from "../cases/utils/utils";
import { BillsWidget } from "./components/BillsWidget";
import { CasesWidget } from "./components/CasesWidget";
import { MessageWidget } from "./components/MessageWidget";

const BillsWidgetMemo = memo(BillsWidget);
const MessageWidgetMemo = memo(MessageWidget);
const CasesWidgetMemo = memo(CasesWidget);

export type HomeScreenProps = {
  screenViewProps?: Omit<ScreenViewProps, "children">;
};

export function HomeScreen({ screenViewProps }: HomeScreenProps) {
  const {
    loading: billsLoading,
    data: billsData,
    refetch: refetchBills,
  } = useNormalizedEobs(allFilter);
  const {
    loading: issuesLoading,
    data: issuesData,
    refetch: refetchIssues,
  } = useQuery(getIssuesQueryV2, {
    context: errorHandling("caller"),
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    refetchWritePolicy: "merge",
    variables: filterMap[filterOptionIds.open],
  });

  const handleRefresh = async () => {
    await Promise.all([refetchBills(), refetchIssues()]);
  };

  return (
    <ScreenView
      noPadding
      backgroundColor="$backgroundSecondary"
      onRefresh={handleRefresh}
      {...screenViewProps}
    >
      <MessageWidgetMemo />
      <BillsWidgetMemo loading={billsLoading} data={billsData} />
      <CasesWidgetMemo loading={issuesLoading} data={issuesData} />
    </ScreenView>
  );
}
