import { useCallback } from "react";
import { useRouter } from "solito/router";

import { MessageSquare as MessageSquareIcon } from "@tamagui/lucide-icons";

import { IssueType, type QueryIssuesV2Query } from "@medbillai/graphql-types";

import {
  Circle,
  FullscreenSpinner,
  LoadingButton,
  Paragraph2,
  Subheadline5,
  YStack,
} from "@medbillai/ui";

import { issuePreviewUrl } from "@medbillai/utils/urls";

import { CaseListItem } from "../../cases/components/CaseListItem";
import type { CaseListProps } from "../../cases/utils/types";
import { EmptyWidgetWrapper } from "./EmptyWidgetWrapper";
import { WidgetHeader } from "./WidgetHeader";

export const CasesWidget = ({
  loading,
  data,
}: {
  loading: boolean;
  data: QueryIssuesV2Query | undefined;
}) => {
  const { push } = useRouter();

  const issues = data?.queryIssuesV2?.slice(0, 5) || [];
  const isEmpty = issues.length === 0;

  const handlePress = useCallback(() => {
    push(issuePreviewUrl(IssueType.ONBOARDING));
  }, [isEmpty]);

  return (
    <YStack paddingVertical="$4" paddingHorizontal="$0">
      <WidgetHeader label="Open Cases" onPress={handlePress} />
      <YStack>
        {isEmpty ? (
          <EmptyCaseList handlePress={handlePress} loading={loading} />
        ) : (
          <CaseList issues={issues} />
        )}
      </YStack>
    </YStack>
  );
};

const CaseList = ({ issues }: CaseListProps) => {
  return (
    <YStack>
      {issues.map((issue, index) => (
        <CaseListItem key={issue.id} issue={issue} borderHide={index == 0} />
      ))}
    </YStack>
  );
};

const EmptyCaseList = ({
  handlePress,
  loading,
}: {
  handlePress: () => void;
  loading: boolean;
}) => {
  return (
    <>
      {loading ? (
        <FullscreenSpinner />
      ) : (
        <EmptyWidgetWrapper>
          <Circle
            size="$5"
            backgroundColor="$backgroundTertiary"
            zIndex={2}
            justifyContent="center"
            alignItems="center"
            mb="$3.5"
          >
            <MessageSquareIcon
              size={24}
              strokeWidth={2}
              color="$textSecondary"
            />
          </Circle>
          <Subheadline5>No cases</Subheadline5>
          <Paragraph2 theme="alt1" textAlign="center">
            {"Your cases will appear here  \n when you have them."}
          </Paragraph2>
          <LoadingButton
            onPress={handlePress}
            size="$3"
            width="$12"
            height="$3"
            mt="$5"
          >
            Start a Case
          </LoadingButton>
        </EmptyWidgetWrapper>
      )}
    </>
  );
};
