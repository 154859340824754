import type { ReactNode } from "react";

import { Spinner, XStack, YBlock, YStack } from "@medbillai/ui";

export const EmptyWidgetWrapper = ({ children }: { children: ReactNode[] }) => {
  return (
    <YStack padding="$4" width="100%">
      <YBlock
        padding="$7"
        borderRadius="$8"
        backgroundColor="$backgroundSecondary"
        borderColor="$strokeSecondary"
        borderWidth={0.5}
        shadowColor="$shadowColor"
        shadowOffset={{ height: 5, width: 0 }}
        shadowRadius={5}
        justifyContent="center"
        alignItems="center"
        gap="$1"
      >
        {children}
      </YBlock>
    </YStack>
  );
};

export const EmptyLoadingWidget = () => {
  return (
    <XStack
      justifyContent="center"
      alignItems="center"
      animation="bouncyTight"
      height="$6"
    >
      <Spinner />
    </XStack>
  );
};
