import { DollarSign as DollarSignIcon } from "@tamagui/lucide-icons";

import { Circle, Paragraph2, Subheadline5, YStack } from "@medbillai/ui";

import { BillListItem } from "../../bill/components/BillListItem";
import type { NormalizedEob } from "../../bill/utils/types";
import { EmptyLoadingWidget, EmptyWidgetWrapper } from "./EmptyWidgetWrapper";
import { WidgetHeader } from "./WidgetHeader";

export const BillsWidget = ({
  data,
  loading,
}: {
  data: NormalizedEob[];
  loading: boolean;
}) => {
  const eobs = data.slice(0, 5) || [];
  const isEmpty = eobs.length === 0;

  return (
    <YStack
      paddingVertical="$4"
      paddingBottom="$4"
      paddingHorizontal="$0"
      borderBottomWidth={0.5}
      borderBottomColor="$strokeSecondary"
    >
      <WidgetHeader label="Recent Bills" />
      <YStack>
        {isEmpty ? (
          <EmptyBillsList loading={loading} />
        ) : (
          <BillList eobs={eobs} />
        )}
      </YStack>
    </YStack>
  );
};

const BillList = ({ eobs }: { eobs: NormalizedEob[] }) => {
  return (
    <YStack>
      {eobs.map((eob, index) => (
        <BillListItem key={eob.id} eob={eob} borderHide={index == 0} />
      ))}
    </YStack>
  );
};

const EmptyBillsList = ({ loading }: { loading: boolean }) => {
  return (
    <>
      {loading ? (
        <EmptyLoadingWidget />
      ) : (
        <EmptyWidgetWrapper>
          <Circle
            size="$5"
            backgroundColor="$backgroundTertiary"
            zIndex={2}
            justifyContent="center"
            alignItems="center"
            mb="$3.5"
          >
            <DollarSignIcon size={24} strokeWidth={2} color="$textSecondary" />
          </Circle>
          <Subheadline5>No bills</Subheadline5>
          <Paragraph2 theme="alt1" textAlign="center">
            {"Your recent bills will appear \n here when you have them."}
          </Paragraph2>
        </EmptyWidgetWrapper>
      )}
    </>
  );
};
