import { useState } from "react";
import { useRouter } from "solito/router";

import { IssueType } from "@medbillai/graphql-types";

import { Paragraph2, Subheadline5, YBlock, YStack } from "@medbillai/ui";

import { issuePreviewUrl } from "@medbillai/utils/urls";

import CaseInput from "../../case/components/CaseInput";
import { HomePageIssueOverlay } from "../../case/components/new-issue-overlay/HomePageIssueOverlay";

export const MessageWidget = () => {
  const { push } = useRouter();
  const [selectedMessage, setSelectedMesssage] = useState<string | undefined>(
    undefined,
  );
  const [hidePrompt, setHidePrompt] = useState<boolean>(false);

  const selectMessageSuggestion = (title: string) => {
    setSelectedMesssage(title);
  };

  // TODO: add upload capability once the document upload provider is refreshed
  function openUploadSheet(): void {
    return;
  }

  const executeSendMessage = (title: string) => {
    push(issuePreviewUrl(IssueType.UNKNOWN, title));
  };

  const promptHandler = (hidden: boolean) => {
    setHidePrompt(hidden);
  };

  return (
    <YStack
      padding="$4"
      paddingBottom="$6"
      backgroundColor="$background"
      borderBottomWidth={0.5}
      borderBottomColor="$strokeSecondary"
    >
      <YBlock
        paddingVertical="$5"
        borderRadius="$8"
        paddingHorizontal="$0"
        backgroundColor="$background"
        justifyContent="flex-start"
        borderColor="$strokeSecondary"
        borderWidth={0.5}
        shadowColor="$shadowColor"
        shadowOffset={{ height: 5, width: 0 }}
        shadowOpacity={0.02}
        shadowRadius={3}
      >
        <YStack paddingHorizontal="$5">
          <Subheadline5>How can we help you?</Subheadline5>
          <Paragraph2 theme="alt1">
            Start chatting with one of our health experts.
          </Paragraph2>
        </YStack>
        {/*
         * Margin creates space below text, but must also push low enough to
         * create space for the input, which is absolute-positioned to the
         * bottom of the container. This is done for keyboard-avoiding reasons,
         * so that it expands upwards.
         */}
        <YStack jc="center" mb="$10" mt="$2">
          <HomePageIssueOverlay
            hidePrompt={hidePrompt}
            selectMessageSuggestion={selectMessageSuggestion}
          />
        </YStack>
        <CaseInput
          handleSendMessage={executeSendMessage}
          hidePrompt={promptHandler}
          sendMessageLoading={false}
          uploadDocumentLoading={false}
          openUploadSheet={openUploadSheet}
          suggestedMessage={selectedMessage}
          homePage={true}
        />
      </YBlock>
    </YStack>
  );
};
