import { gql } from "@medbillai/graphql-types";

export const getIssuesQuery = gql(/* GraphQL */ `
  query QueryIssues($input: QueryIssuesInput) {
    queryIssues(input: $input) {
      __typename
      id
      title
      openedAt
      closedAt
      lastEvent {
        __typename
        id
        sentAt
        eventType
        participant
        ... on issue__ChatEvent_Type {
          chatBody
        }
        ... on issue__TemplateReleaseRequestedEvent_Type {
          template {
            description
          }
        }
      }
      firstUnseenEvent {
        __typename
        id
        sentAt
        eventType
        participant
        ... on issue__ChatEvent_Type {
          chatBody
        }
        ... on issue__TemplateReleaseRequestedEvent_Type {
          template {
            description
          }
        }
      }
    }
  }
`);

export const getIssuesQueryV2 = gql(/* GraphQL */ `
  query QueryIssuesV2(
    $closed: Boolean
    $unread: Boolean
    $open: Boolean
    $first: Int
    $after: Int
  ) {
    queryIssuesV2(
      closed: $closed
      unread: $unread
      open: $open
      first: $first
      after: $after
    ) {
      __typename
      id
      title
      openedAt
      closedAt
      lastEvent {
        __typename
        id
        sentAt
        eventType
        participant
        ... on issue__ChatEvent_Type {
          chatBody
        }
        ... on issue__TemplateReleaseRequestedEvent_Type {
          template {
            description
          }
        }
      }
      firstUnseenEvent {
        __typename
        id
        sentAt
        eventType
        participant
        ... on issue__ChatEvent_Type {
          chatBody
        }
        ... on issue__TemplateReleaseRequestedEvent_Type {
          template {
            description
          }
        }
      }
    }
  }
`);
